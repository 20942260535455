import './App.css';
import axios from "axios";
import {useEffect, useState} from "react";
import {Grid, Paper, Typography} from "@mui/material";

function Shops(props) {
    const [visitedShops, setVisitedShops] = useState();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_JOYN_API}/user-portal/shops/visited?page=0&size=20`,
            {
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                }
            })
            .then((response) => {
                setVisitedShops(response.data);
            }).catch((error) => {
            console.log(error);
        })
    }, [props.token])

    return <>
        <h1>My Shops</h1>
        <div className="shops">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={2}>
                        {visitedShops ? visitedShops.content.map((shop) =>
                            <Grid key={shop.id} item xs={4}>
                                <Paper>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <img className={'shopImage'}
                                                 alt={shop.image}
                                                 src={shop.image}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography className={'shopName'}>
                                                        {shop.name}
                                                    </Typography>
                                                    <Typography>
                                                        {shop.balance} points
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </>

}

export default Shops;
