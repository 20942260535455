import './App.css';
import axios from "axios";
import {useEffect, useState} from "react";

function User(props) {
    const [user, setUser] = useState();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_JOYN_API}/api/v2/user/profile`,
            {
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                }
            })
            .then((response) => {
                setUser(response.data);
            }).catch((error) => {
            console.log(error);
        })
    }, [props])

    return <>
        <h1>User</h1>
        <div className="user">
        {user ? (
            <div key={user.id} className="user">
                <div>{user.firstName}</div>
                <div>{user.lastName}</div>
                <div>{user.userReference}</div>
                <div>{user.contact.email}</div>
            </div>
        ) : null}
    </div></>

}

export default User;
