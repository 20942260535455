import './App.css';
import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import jwtDecode from "jwt-decode";
import Shops from "./Shops";
import User from "./User";

function App() {
    const qs = require('query-string');
    const code = qs.parse(window.location.search).code;
    const [rawToken, setRawToken] = useState();
    const [decodedToken, setDecodedToken] = useState();
    const [oauthResponse, setOauthResponse] = useState();
    const [client, setClient] = useState("payconiq");
    const [scopes, setScopes] = useState("profile%20offline_access%20balance%20shop");
    const authLogin = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect`;
    const redirectUri = process.env.REACT_APP_KEYCLOAK_REDIRECT_URI;
    const [locale, setLocale] = useState("nl");
    const [error, setError] = useState();
    const [loggedIn, setLoggedIn] = useState(false);

    const getAccessCode = useCallback((code) => {
        axios.post(`${authLogin}/token`,
            `client_id=${client}&grant_type=authorization_code&code=${code}&redirect_uri=${redirectUri}&scope=${scopes}`)
            .then((response) => {
                let decoded = jwtDecode(response.data.access_token);
                setOauthResponse(response.data)
                setRawToken(response.data);
                setDecodedToken(decoded);
                setLoggedIn(true);
            }).catch((error) => {
            setError(error)
            console.log(error);
        })
    }, [client, redirectUri, authLogin, scopes]);

    useEffect(() => {
        if (code) {
            getAccessCode(code);
        }
    }, [code, getAccessCode]);

    function showUserInfo() {
        return <>
            <div className="token">
                <div><h1>Oauth response</h1></div>
                <div>
                    {Object.keys(oauthResponse).map((key, i) => (
                        <div key={i}>
                            <span className={"tokenLabel"} >{key} : </span>
                            <span className={"tokenValue"}>{oauthResponse[key]}</span>
                        </div>
                    ))}
                </div>
                <div><h1>Decoded access token</h1></div>
                {Object.keys(decodedToken).map((key, i) => (
                    <div key={i}>
                        <span className={"tokenLabel"} >{key} : </span>
                        <span className={"tokenValue"}>{decodedToken[key].toString()}</span>
                    </div>
                ))}

            </div>
            <User token={rawToken.access_token}/>
            <Shops token={rawToken.access_token}/>
        </>
    }

    return (
        <div className="App">
            {loggedIn ?
                <a href={`${authLogin}/logout?redirect_uri=${redirectUri}`}>logout</a>
                :
                <>
                    <div>
                        <p>{`${authLogin}/auth?client_id=`}
                            <input type="text" value={client} onChange={(e) => setClient(e.target.value)}
                                   style={{width: '200px'}}/>
                            <br/>
                            {`&redirect_uri=${redirectUri}`}
                            <br/>
                            &response_type=code
                            <br/>
                            &ui_locales=
                            <select value={locale} onChange={(e) => setLocale(e.target.value)}>
                                <option>nl</option>
                                <option>fr</option>
                                <option>en</option>
                                <option>de</option>
                            </select>
                            <br/>
                            &scope=<input type="text" value={scopes} onChange={(e) => setScopes(e.target.value)}
                                          style={{width: '200px'}}/>
                            <br/>
                        </p>
                    </div>
                    <a href={`${authLogin}/auth?client_id=${client}&redirect_uri=${redirectUri}&response_type=code&ui_locales=${locale}&scope=${scopes}`}>
                        Inloggen met Joyn
                    </a>
                </>
            }

            {rawToken && decodedToken ? showUserInfo() : null}
            {error ? (<p className="error">{error.toString()}</p>) : null}
        </div>
    );
}

export default App;
